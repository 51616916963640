import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './SelectSingleFilterPlain.module.css';
import { IoRadioButtonOffSharp } from 'react-icons/io5';
import { IoRadioButtonOn } from 'react-icons/io5';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  // componentDidUpdate(prevProps) {
  //   const { initialValues, queryParamNames } = this.props;
  //   const queryParamName = getQueryParamName(queryParamNames);
  //   if (prevProps.initialValues !== initialValues) {
  //     this.setState({ isOpen: !!initialValues[queryParamName] });
  //   }
  // }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      // useBorder,
      useHighlight,
      keyword,
      handleKeyword,
      intl,
    } = this.props;

    let { useBorder } = this.props;
    useBorder = true;
    const isDesigner = queryParamNames[0] === 'pub_designer';
    const queryParamName = getQueryParamName(queryParamNames);
    const notCategory = queryParamName !== 'pub_department';
    const isDepartment = queryParamName === 'pub_department';

    let initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    initialValue = isDepartment ? (initialValue ? initialValue : 'women') : initialValue;
    const labelClass = initialValue ? css.labelSelected : css.label;

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{label}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>
        {isDesigner ? (
          <input
            type="text"
            className={css.search}
            value={keyword}
            placeholder={
              intl.formatMessage({
                id: 'SelectSingleFilter.SelectSingleFilterPlain.placeholder',
              }) + '...'
            }
            onChange={e => handleKeyword(e)}
          />
        ) : null}
        <div className={optionsContainerClass}>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {selected ? (
                  <IoRadioButtonOn className={css.radioIcon} />
                ) : (
                  <IoRadioButtonOffSharp className={css.radioIcon} />
                )}
                {option.label}
              </button>
            );
          })}
          {options.length === 0 && isDesigner ? (
            <div className={css.noOptions}>
              <FormattedMessage id="SelectSingleFilter.SelectSingleFilterPlain.noMatchLabel" />
            </div>
          ) : null}
          {notCategory && initialValue ? (
            <button className={css.clearButton} onClick={e => this.selectOption(null, e)}>
              <FormattedMessage id={'SelectSingleFilter.plainClear'} />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlain;
