import React, { useEffect, useState, useMemo } from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';
import { useIntl } from 'react-intl';

const SelectSingleFilter = props => {
  const { showAsPopup, options, ...rest } = props;
  const [keyword, setKeyword] = useState('');
  const intl = useIntl();
  let filterKeywordOptions = [...(options ?? [])];
  filterKeywordOptions = useMemo(() => {
    if (keyword !== '') {
      return [...filterKeywordOptions].filter(item =>
        item.label.toLowerCase().includes(keyword.toLowerCase())
      );
    } else {
      return options;
    }
  }, [keyword, options]);
  const handleKeyword = e => {
    setKeyword(e.target.value);
  };
  return showAsPopup ? (
    <SelectSingleFilterPopup {...rest} />
  ) : (
    <SelectSingleFilterPlain
      {...rest}
      keyword={keyword}
      handleKeyword={handleKeyword}
      intl={intl}
      options={
        filterKeywordOptions?.length > 0 ? [...new Set([...(filterKeywordOptions || [])])] : []
      }
    />
  );
};

SelectSingleFilter.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilter;
